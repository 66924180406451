import React from 'react';
import PropTypes from 'prop-types';

import Section from 'components/Section';

import classes from './PageContent.scss';

function PageContent({ children }) {
  return (
    <Section className={classes.root}>
      <div className={classes.content}>{children}</div>
    </Section>
  );
}

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContent;
