import React from 'react';
import PropTypes from 'prop-types';

import PageContent from 'blocks/PageContent';
import Image from 'blocks/Image';

import Icon from 'components/Icon';
import Heading from 'components/Heading';
import Text from 'components/Text';

import classes from './BlogContent.scss';

function BlogContent({ item }) {
  const date = new Date(item._created * 1000);
  const dateString = date.toLocaleDateString('nl', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  return (
    <PageContent>
      <Text weight="semi" color="primary-lighter" spacing="xs">
        <Icon name="user" /> {item.author} - <Icon name="clock" /> {dateString}
      </Text>

      <Heading as="h1" size="xl">
        {item.title}
      </Heading>

      <Text>
        <span dangerouslySetInnerHTML={{ __html: item.contentBefore }} />
      </Text>

      {item.textImage && (
        <div className={classes.image}>
          <Image image={item.textImage} alt={item.title} noShadow />
        </div>
      )}

      <Text>
        <span dangerouslySetInnerHTML={{ __html: item.contentAfter }} />
      </Text>
    </PageContent>
  );
}

BlogContent.propTypes = {
  item: PropTypes.shape({
    author: PropTypes.string.isRequired,
    _created: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    contentBefore: PropTypes.string.isRequired,
    textImage: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    contentAfter: PropTypes.string.isRequired,
  }).isRequired,
};

export default BlogContent;
