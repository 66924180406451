import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Title from 'blocks/Title';
import BlogContent from 'blocks/BlogContent';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';

const Blog = ({ data }) => {
  const { item } = data;

  return (
    <Layout>
      <Seo
        image={item.image}
        title={item.seoTitle}
        description={item.seoDescription}
      />

      <Title image={item.image} alt={item.title} onlyImage />

      <BlogContent item={item} />

      <ContactCta />
    </Layout>
  );
};

Blog.propTypes = {
  data: PropTypes.shape({
    item: PropTypes.shape({
      seoTitle: PropTypes.string.isRequired,
      seoDescription: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      _created: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      contentBefore: PropTypes.string.isRequired,
      textImage: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      contentAfter: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query blogItem($slug: String!) {
    item: blog(slug: { eq: $slug }) {
      seoTitle
      seoDescription
      author
      _created
      title
      image {
        sharpImage: childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        publicURL
      }
      contentBefore
      textImage {
        sharpImage: childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        publicURL
      }
      contentAfter
    }
  }
`;

export default Blog;
